import React from "react"
import { graphql } from "gatsby"
import Buttons from '../content/Buttons'
import './hero-banner.module.scss'

const HeroBanner = ({ heroBanner }) => {
  const { backgroundImage, heading, subheading, buttons } = heroBanner

  return (
    <section className={`hero-banner`}>
      <section className={`hero-banner-image`}>
        <img src={backgroundImage.file.url} alt={backgroundImage.description} />
        <div className={`banner-overlay`}></div>
      </section>
      <section className={`container hero-banner-content`}>
        <h1>{heading}</h1>
        {subheading && (<h2>{subheading}</h2>)}
        {buttons && (
          <div className={`home-page-banner-links`}>
            <Buttons buttons={buttons} variant={'white-accent-ghost-button'} />
          </div>
        )}
      </section>
    </section>
  )
}

export default HeroBanner

export const query = graphql`
  fragment HeroBannerFragment on ContentfulHeroBanner {
    backgroundImage {
      description
      file {
        url
      }
    }
    heading
    subheading
    buttons {
      ...Button
    }
  }
`